import { Inject, Pipe, PipeTransform } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  ELM_MOMENT_DATE_FORMATS as ELM_DATE_FORMATS,
  ElmDatePipeFormat
} from '@element451-libs/models451';
import { parseDate } from '@element451-libs/utils451/dates';
import * as moment from 'moment';

// TODO: refactor importing these from lib451 at other places.
export { ELM_DATE_FORMATS, ElmDatePipeFormat };

export enum DateFromNowLabels {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Tomorrow = 'Tomorrow',
  ThisWeek = 'This Week',
  ThisMonth = 'This Month',
  ThisYear = 'This Year'
}

@Pipe({ name: 'elmDate' })
export class DatePipe implements PipeTransform {
  transform(
    value: string | Date,
    pattern: ElmDatePipeFormat = ElmDatePipeFormat.MediumDate
  ): string {
    return value && formatDate(value, pattern);
  }
}

@Pipe({ name: 'elmUnixDate' })
export class DateUnixPipe implements PipeTransform {
  transform(
    value: number,
    pattern: ElmDatePipeFormat = ElmDatePipeFormat.MediumDate
  ): string | number {
    return value && formatUnixDate(value, pattern);
  }
}

@Pipe({ name: 'elmLegacyDate' })
export class LegacyDatePipe implements PipeTransform {
  transform(
    value: string | Date,
    pattern: ElmDatePipeFormat = ElmDatePipeFormat.MediumDate
  ): string {
    return value && formatLegacyDate(value, pattern);
  }
}

@Pipe({ name: 'elmDateUTC' })
export class DateUTCPipe implements PipeTransform {
  transform(
    value: string | Date,
    pattern: ElmDatePipeFormat = ElmDatePipeFormat.MediumDate
  ): string {
    return value && formatDateUTC(value, pattern);
  }
}

export function formatDate(
  value: string | Date | moment.Moment,
  pattern: ElmDatePipeFormat | string = ElmDatePipeFormat.MediumDate
): string {
  const formatPattern = ELM_DATE_FORMATS[pattern] || pattern;

  const parsed = moment(value);

  if (parsed.isValid()) {
    return parsed.format(formatPattern);
  }

  console.error('Failure parsing', value);
  return '';
}

export function formatUnixDate(
  value: number,
  pattern: ElmDatePipeFormat | string = ElmDatePipeFormat.MediumDate
): string {
  const formatPattern = ELM_DATE_FORMATS[pattern] || pattern;

  const parsed = moment.unix(value);
  if (parsed.isValid()) {
    return parsed.format(formatPattern);
  }

  console.error('Failure parsing', value);
  return '';
}

export function formatLegacyDate(
  value: string | Date | moment.Moment,
  pattern: ElmDatePipeFormat | string = ElmDatePipeFormat.MediumDate
): string {
  const formatPattern = ELM_DATE_FORMATS[pattern] || pattern;
  const legacyDateFormat = 'ddd MMM DD HH:mm:ss Z YYYY';

  const oldParse = moment(value, legacyDateFormat);
  if (oldParse.isValid()) {
    return oldParse.format(formatPattern);
  }

  const newParse = moment(value);
  if (newParse.isValid()) {
    return newParse.format(formatPattern);
  }

  console.error('Failure parsing', value);
  return '';
}

export function formatDateUTC(
  value: string | Date | moment.Moment,
  pattern: ElmDatePipeFormat | string = ElmDatePipeFormat.MediumDate
): string {
  const formatPattern = ELM_DATE_FORMATS[pattern] || pattern;

  const parsed = moment(value);
  if (parsed.isValid()) {
    return parsed.utc().format(formatPattern);
  }

  console.error('Failure parsing', value);
  return '';
}

// port of moment.js calendar functionality
export interface ElmCalendarOptions {
  sameDay: string;
  lastDay: string;
  lastWeek: string;
  sameElse: string;
}

export type ElmCalendarDatePipeFormat =
  | 'dateTimeCalendar'
  | 'fullDateTimeCalendar'
  | 'timeCalendar';

export const ELM_CALENDAR_DATE_FORMATS: {
  [key: string]: ElmCalendarOptions;
} = {
  dateTimeCalendar: {
    sameDay: 'h:mm A',
    lastDay: '[Yesterday] h:mm A',
    lastWeek: 'dddd h:mm A',
    sameElse: 'MMM D h:mm A'
  },
  fullDateTimeCalendar: {
    sameDay: '[Today] h:mm A',
    lastDay: '[Yesterday] h:mm A',
    lastWeek: 'dddd h:mm A',
    sameElse: 'MMMM D, YYYY h:mm A'
  },
  timeCalendar: {
    sameDay: 'h:mm A',
    lastDay: 'h:mm A',
    lastWeek: 'h:mm A',
    sameElse: 'h:mm A'
  }
};

export function formatCalendar(
  value: string | Date,
  pattern: ElmCalendarDatePipeFormat
): string {
  const calendarPattern = ELM_CALENDAR_DATE_FORMATS[pattern];

  const parsedDate = parseDate(value);
  const date = moment(parsedDate);

  if (isToday(date)) {
    return date.format(calendarPattern.sameDay);
  } else if (isYesterday(date)) {
    return date.format(calendarPattern.lastDay);
  } else if (isThisWeek(date)) {
    return date.format(calendarPattern.lastWeek);
  } else {
    return date.format(calendarPattern.sameElse);
  }
}

@Pipe({ name: 'elmCalendarDate' })
export class CalendarDatePipe implements PipeTransform {
  transform(
    value: string | Date,
    pattern: ElmCalendarDatePipeFormat = 'dateTimeCalendar'
  ): string {
    return formatCalendar(value, pattern);
  }
}

@Pipe({ name: 'elmFromDateToNow' })
export class FromDateToNowPipe implements PipeTransform {
  constructor(@Inject(MAT_DATE_LOCALE) private defaultLocale: string) {}

  transform(value: string | Date): string {
    return value ? moment(value).locale(this.defaultLocale).fromNow() : '';
  }
}

const DATE_TO_NOW_SHORT_LOCALE = 'elmFromDateToNowShort';
moment.defineLocale(DATE_TO_NOW_SHORT_LOCALE, {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1M',
    MM: '%dM',
    y: '1Y',
    yy: '%dY'
  }
});

@Pipe({ name: 'elmFromDateToNowShort' })
export class FromDateToNowShortPipe implements PipeTransform {
  transform(value: string | Date): string {
    return value
      ? moment(value).locale(DATE_TO_NOW_SHORT_LOCALE).fromNow()
      : '';
  }
}

@Pipe({ name: 'elmChronologicalDate' })
export class DateChronologicalPipe implements PipeTransform {
  transform(
    value: string | Date,
    pattern: ElmDatePipeFormat = ElmDatePipeFormat.MediumDate
  ): string {
    return dateToChronologicalString(value, pattern);
  }
}

const DATE_TO_NOW_ACCESSIBILITY_LOCALE = 'dateToNowAccessibility';
moment.defineLocale(DATE_TO_NOW_ACCESSIBILITY_LOCALE, {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1 second',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years'
  }
});

@Pipe({ name: 'elmFromDateToNowAccessibility' })
export class FromDateToNowAccessibilityPipe implements PipeTransform {
  transform(value: string | Date): string {
    return value
      ? moment(value).locale(DATE_TO_NOW_ACCESSIBILITY_LOCALE).fromNow()
      : '';
  }
}

export function dateToChronologicalString(
  date: string | Date,
  pattern: ElmDatePipeFormat
) {
  if (isToday(moment(date))) return DateFromNowLabels.Today;
  if (isYesterday(moment(date))) return DateFromNowLabels.Yesterday;
  if (isTomorrow(moment(date))) return DateFromNowLabels.Tomorrow;

  return formatDate(date, pattern);
}

export function isToday(momentDate: moment.Moment) {
  const TODAY = moment().startOf('day');
  return momentDate.isSame(TODAY, 'd');
}

export function isYesterday(momentDate: moment.Moment) {
  const YESTERDAY = moment().subtract(1, 'days').startOf('day');
  return momentDate.isSame(YESTERDAY, 'd');
}

export function isPast(momentDate: moment.Moment) {
  const TODAY = moment().startOf('day');
  return momentDate.isBefore(TODAY, 'd');
}

export function isTomorrow(momentDate: moment.Moment) {
  const TOMORROW = moment().add(1, 'days').startOf('day');
  return momentDate.isSame(TOMORROW, 'd');
}

export function isThisWeek(momentDate: moment.Moment) {
  const A_WEEK_OLD = moment().subtract(7, 'days').startOf('day');
  return momentDate.isAfter(A_WEEK_OLD);
}
