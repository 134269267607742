import {
  WebhooksApi_GetWebhookQuery as GetWebhook,
  WebhooksApi_GetWebhookQueryVariables as GetWebhookVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { GetWebhook, GetWebhookVariables };

export const getWebhook = gql`
  query WebhooksApi_GetWebhook($guid: String!) {
    webhook(input: { guid: $guid }) {
      guid
      name
      config {
        url
        method
        webhook_body_type
        payload
        payload_template_guid
        headers {
          name
          value
        }
      }
      throttle_config {
        requests_limit
        requests_timeout
      }
      updated_at
      created_at
    }
  }
`;
