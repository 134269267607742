import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TasksApi } from '@element451-libs/models451';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { responseData } from '../rxjs';
import { Api451Done, ElmResponse } from '../shared';
import { TasksApiModule } from './tasks-api.module';

type R<T> = ElmResponse<T>;

abstract class BaseTaskStatusesApiService {
  protected baseURL: string;

  constructor(
    protected http: HttpClient,
    @Inject(API451_URL_FACTORY) protected url: UrlFactory,
    baseURL: string
  ) {
    this.baseURL = baseURL;
  }

  getAll() {
    return this.http
      .get<R<TasksApi.Status[]>>(this.url(this.baseURL))
      .pipe(responseData);
  }

  get(guid: string) {
    return this.http
      .get<R<TasksApi.Status>>(this.url(`${this.baseURL}/${guid}`))
      .pipe(responseData);
  }

  add(createDto: TasksApi.StatusDto) {
    return this.http
      .post<R<TasksApi.Status>>(this.url(`${this.baseURL}`), createDto)
      .pipe(responseData);
  }

  update(guid: string, updateDto: TasksApi.StatusDto) {
    return this.http
      .put<R<TasksApi.Status>>(this.url(`${this.baseURL}/${guid}`), updateDto)
      .pipe(responseData);
  }

  delete(guid: string, replacementGuid?: string) {
    return this.http
      .delete<R<Api451Done>>(this.url(`${this.baseURL}/${guid}`), {
        body: {
          replacement_status_guid: replacementGuid
        }
      })
      .pipe(responseData);
  }

  reorder(guid: string, newIndex: number) {
    return this.http
      .post<
        R<[]>
      >(this.url(`${this.baseURL}/${guid}/reorder/${newIndex}`), null)
      .pipe(responseData);
  }
}

@Injectable({ providedIn: TasksApiModule })
export class TaskStatusesApiService extends BaseTaskStatusesApiService {
  constructor(http: HttpClient, @Inject(API451_URL_FACTORY) url: UrlFactory) {
    super(http, url, 'people/tasks/settings/statuses');
  }
}

@Injectable({ providedIn: TasksApiModule })
export class ExternalTaskStatusesApiService extends BaseTaskStatusesApiService {
  constructor(http: HttpClient, @Inject(API451_URL_FACTORY) url: UrlFactory) {
    super(http, url, 'people/tasks/settings/external_statuses');
  }
}
