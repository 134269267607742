import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthApi } from '@element451-libs/models451';
import { isString } from 'lodash';
import { Observable } from 'rxjs';

import {
  API451_CLIENT,
  API451_URL_FACTORY,
  ApiClient,
  UrlFactory
} from '../api-client';
import { ElmResponse, ElmResponse2, rootUrl } from '../shared';
import { AuthApiModule } from './auth-api.module';

// shorthand
type R<T> = ElmResponse<T>;
type R2<T> = ElmResponse2<T>;

@Injectable({
  providedIn: AuthApiModule
})
export class AuthApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory,
    @Inject(API451_CLIENT) private apiClient: ApiClient
  ) {}

  // Overloaded signatures
  internalLogin(mfa_code: string): Observable<R<AuthApi.LockerLoginResponse>>;
  internalLogin(): Observable<R<AuthApi.LoginResponse>>;
  internalLogin(mfa_token?: string) {
    let params = new HttpParams();

    if (mfa_token) {
      params = params.set('mfa_token', mfa_token);
    }

    return this.http.post(this.rootUrl('auth/basic/internal'), null, {
      params
    });
  }

  // Overloaded signatures
  externalLogin(mfa_code: string): Observable<R<AuthApi.LockerLoginResponse>>;
  externalLogin(): Observable<R<AuthApi.LoginResponse>>;
  externalLogin(mfa_token?: string) {
    let params = new HttpParams();

    if (mfa_token) {
      params = params.set('mfa_token', mfa_token);
    }
    return this.http.post(this.rootUrl('auth/basic/external'), null, {
      params
    });
  }

  loginLocker(locker: string, namespace?: string) {
    return this.http.get<R<AuthApi.Account>>(
      this.url(`login/locker/${locker}`),
      {
        params: namespace ? new HttpParams({ fromObject: { namespace } }) : null
      }
    );
  }

  loginLockerRequest(email: string, app_url: string) {
    return this.http.post<R<AuthApi.Account>>(
      this.url(`login/locker/request`),
      { email, app_url }
    );
  }

  logout() {
    return this.http.get<R<AuthApi.Account>>(this.url('logout'));
  }

  logoutRedirect() {
    const locker = isString(this.apiClient.authorization)
      ? this.apiClient.authorization
      : null;
    window.location.href = this.url('logout') + `?locker=${locker}`;
  }

  getImpersonateUserLocker(
    userId: string,
    params?: AuthApi.ImpersonateUserParams
  ) {
    return this.http.post<R2<{ key: string }>>(
      this.url(`login/impersonate-student`),
      {
        user_id: userId,
        ...(params || {})
      }
    );
  }

  private rootUrl(url: string) {
    return rootUrl(this.url(url));
  }
}
