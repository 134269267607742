import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ValidationsApiModule } from './validations-api.module';

const cacheMap = new Map();

@Injectable({
  providedIn: ValidationsApiModule
})
export class PhoneApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  verify(
    countryCode: string,
    number: string
  ): Observable<{ valid: boolean; dueToError?: boolean }> {
    const phone = countryCode + number;
    if (cacheMap.get(phone)) {
      return cacheMap.get(phone);
    }

    const phoneNumber = `+${countryCode || ''}${number}`;
    const params = new HttpParams().set('phone', phoneNumber);
    const stream$ = this.http
      .get(this.url('validations/phones'), { params })
      .pipe(
        map(() => ({ valid: true }) as const),
        catchError((error: HttpErrorResponse) => {
          switch (error.status) {
            case 400:
              return of({ valid: false } as const);
            // allow to pass through if it is a rate limit error
            // or something else that is not tied to verification
            default:
              return of({ valid: true, dueToError: true } as const);
          }
        }),
        tap(response => {
          if (response.valid === true && 'dueToError' in response) {
            cacheMap.delete(phone); // clear entry if set if we detect an error happend
          }
        })
      );

    const cachedStream$ = stream$.pipe(shareReplay(1));
    cacheMap.set(phone, cachedStream$);

    return cachedStream$;
  }
}
