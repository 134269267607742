import { ApiFile } from '../files';
import { FontsApi } from '../google-fonts';
import { UserType } from '../users/user.models';
import { ZoomApi } from '../zoom';

export interface Settings {
  client: ClientSettings;
  global: GlobalSettings;
}

export interface LoginTextItem {
  loginCapture: string;
}

export interface ClientEmail {
  default: boolean;
  index_weight: number;
  name: string;
  value: string;
  valid_domain?: boolean;
}

export type ClientEmailDto = Pick<ClientEmail, 'name' | 'value' | 'default'>;

export interface GlobalSettings {
  analytics_microsite_token: string[];
  email_addresses: ClientEmail[];
}

export const DefaultFonts: {
  [key in keyof FontsSettings]: Partial<FontsApi.Font>;
} = {
  primary: {
    family: 'Helvetica',
    category: FontsApi.Category.SansSerif
  },
  secondary: {
    family: 'Georgia',
    category: FontsApi.Category.Serif
  }
};

export const isSystemFont = (font: FontsApi.Font) =>
  [DefaultFonts.primary?.family, DefaultFonts.secondary?.family].includes(
    font.family
  );

export interface FontsSettings {
  primary?: FontsApi.Font;
  secondary?: FontsApi.Font;
}

export enum SettingsKeys {
  SeePacksFeature = 'see_packs_feature',
  Zoom = 'zoom',
  CalculatedSegmentLimit = 'calculated_segment_limit'
}

export interface CalculatedSegmentLimitSetting {
  key: SettingsKeys.CalculatedSegmentLimit;
  value: number;
}

export interface SeePacksFeatureSetting {
  key: SettingsKeys.SeePacksFeature;
  value: boolean;
}

export interface ZoomSetting {
  key: SettingsKeys.Zoom;
  value: {
    /**
     * email with which user is logged in to zoom
     */
    email: string;
    /**
     * with which app type he is logged in, his own account or company wide account
     */
    app_type: ZoomApi.AppType;
  };
}

export type SettingsList = (
  | SeePacksFeatureSetting
  | ZoomSetting
  | CalculatedSegmentLimitSetting
)[];

export interface AuthenticationSettings {
  admin_authentication: ClientSettingsAuthentication[];
  student_authentication: ClientSettingsAuthentication[];
}

export enum ElementPackage {
  Bolt = 'bolt',
  Ignite = 'ignite',
  Engage = 'engage',
  Discovery = 'discovery'
}

interface CommonClientSettings extends AuthenticationSettings {
  color: string;
  cookies: CookiesSetting;
  favicon_ico?: ApiFile | null;
  favicon_png?: ApiFile | null;
  fonts?: FontsSettings;
  google_tag_id?: string;
  link_color?: string;
  logo: LogoSetting;
  name: string;
  package_type?: string;
  privacy: PrivacySetting;
  secondary_color: string;
  timezone: string;
  token: string;
  saml2_idp_key?: string;
  writing_style?: WritingStyle;
  saml2_idp_public_key?: string;
}

export enum ClientAuthenticationType {
  Google = 'google',
  Sso = 'sso',
  Password = 'password',
  ApiKey = 'api_key'
}

export interface ClientSettingsAuthentication {
  type: ClientAuthenticationType;
  enabled: boolean;
  use_mfa?: boolean;
}

export interface ClientSettings extends CommonClientSettings {
  app_url: string;
  updated_at: string;
  created_at: string;
  signatures: SignatureSetting[];
  contact: ContactSetting[];
  files: { [key: string]: string };
  analytics_microsite_token: string[];
  default_messages: MessagesSetting;
  settings: SettingsList;
  insights?: InsightsDashboard[];
  type?: 'sandbox';
  support_category?: SupportCategory | null;
  live_support?: string[];
  live_support_limit?: number;
}

export interface PublicClientSettings extends CommonClientSettings {
  active: boolean;
}

export enum SupportCategory {
  Standard = 'standard',
  Partner = 'partner',
  Dedicated = 'dedicated'
}

export interface InsightsDashboard {
  /**
   * Title od the dasboard
   */
  name: string;
  /**
   * External Identifier for the dashboard, used in navigation as well
   */
  slug: string;
}

export interface FeatureFlag {
  key: string;
  value: boolean;
}

export interface SignatureSetting {
  name: string;
  phone?: string;
  type: string;
  body: string;
}

export interface ContactSetting {
  department_name: string;
  address: string;
  phone: string;
  fax: string;
  email: string;
  type: string;
  city: string;
  building: string;
  room: string;
  state: string;
  zip: string;
  fullAddress: string;
  contact_name?: string;
  guid?: number;
}

export interface LiveSupportSettingsDto {
  live_support: string[];
}

export interface Logo {
  url: string;
  guid: string;
}

export interface LogoSetting {
  without_color: Logo | null;
  with_color: Logo | null;
  square: Logo | null;
}

export interface MessagesSetting {
  event_messages?: {
    confirmation: string;
    reminde: string;
    confimation_payment: string;
    custom: string;
  };
}

export interface CookiesSetting {
  show_acceptance_question: boolean;
}

export interface PrivacySetting {
  privacy_policy_link: string;
  show_privacy_policy: boolean;
}

export interface WritingStyle {
  overall_writing_style: string;
  tone: string;
  voice: string;
  point_of_view: string;
  writing_intent: string;
  language_and_vocabulary: string;
  formatting_and_structure: string;
}

export interface SamlServiceConfig {
  binding: string;
  url: string;
}

export interface SamlIdp {
  entityId: string;
  singleLogoutService: SamlServiceConfig;
  singleSignOnService: SamlServiceConfig;
  x509cert: string;
}

export type UserTypeSsoSettings = UserType.external | UserType.internal;

export interface SamlAuthenticationSettings {
  active: boolean;
  description: string;
  driver: string;
  idp: SamlIdp;
  name: string;
  provider: string;
  target_user_types: UserTypeSsoSettings[];
  metadata_url: string;
}

export interface SamlAuthenticationSettingsDto {
  target_user_types: UserTypeSsoSettings[];
  xml: string;
}
