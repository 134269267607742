<button mat-icon-button mat-dialog-close *ngIf="displayCloseButton" class="elm-dialog-close">
  <elm-icon icon="close"></elm-icon>
</button>

<div mat-dialog-title [class.elm-no-border-bottom]="!titleBorder" #elmDialogHeader>
  <span class="elm-dialog-header">
    <ng-content select="elm-dialog-title"></ng-content>
    <ng-content select="elm-dialog-description"></ng-content>
  </span>
</div>
<mat-dialog-content #elmDialogContent>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <ng-content select="elm-dialog-content"></ng-content>
</mat-dialog-content>
<mat-dialog-actions [class.elm-no-border-top]="!actionsBorder" #elmDialogActions>
  <ng-content select="elm-dialog-reject"></ng-content>
  <ng-content select="elm-dialog-actions"></ng-content>
</mat-dialog-actions>
