import {
  TasksApi_GetSubtasksQuery as GetSubtasks,
  TasksApi_GetSubtasksQueryVariables as GetSubtasksVariables,
  Task as Subtask
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { GetSubtasks, GetSubtasksVariables, Subtask };

export const getSubtasks = gql`
  query TasksApi_GetSubtasks($parentGuid: String!) {
    subtasks(input: { parentGuid: $parentGuid }) {
      guid
      name
      due_datetime
      type_guid
      status_guid
      status
      priority_guid
      related_to
      assigned_to
      description
      parent_task_guid
      category
      related_user_can_see_task
      assignee_can_complete
      created_by
      created_at
      updated_at
    }
  }
`;
