import {
  TasksTemplatesAPI_TasksTemplatesQuery as TasksTemplates,
  TasksTemplatesAPI_TasksTemplatesQueryVariables as TasksTemplatesVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getTasksTemplates = gql<TasksTemplates, TasksTemplatesVariables>`
  query TasksTemplatesAPI_TasksTemplates(
    $q: String!
    $offset: Int!
    $limit: Int!
    $sort: String
  ) {
    getTasksTemplates(
      input: { q: $q, offset: $offset, limit: $limit, sort: $sort }
    ) {
      tasksTemplates {
        guid
        name
        type_guid
        status_guid
        priority_guid
        due_datetime_type
        due_datetime
        due_days
        assigned_to
        category
        related_user_can_see_task
        assignee_can_complete
        parent_task_guid
        created_at
        created_by
      }
      hasNextPage
      hasPrevPage
      limit
      page
    }
  }
`;
