import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { ApplicationsApi } from '@element451-libs/api451';

import * as actions from './recommend.actions';
import * as selectors from './recommend.reducer';

@Injectable()
export class RecommendService {
  loading$ = this.store.pipe(select(selectors.selectLoading));

  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  submitting$ = this.store.pipe(select(selectors.selectSubmitting));

  student$ = this.store.pipe(select(selectors.selectStudent));

  loginInfo$ = this.store.pipe(select(selectors.selectLoginInfo));

  submitted$ = this.store.pipe(select(selectors.selectSubmitted));

  documents$ = this.store.pipe(select(selectors.selectDocuments));

  form$ = this.store.pipe(select(selectors.selectForm));

  uploadedFiles$ = this.store.pipe(select(selectors.selectUploadedFiles));

  formData$ = this.store.pipe(select(selectors.selectFormData));

  headerTitle$ = this.store.pipe(select(selectors.selectHeaderTitle));

  headerDescription$ = this.store.pipe(
    select(selectors.selectHeaderDescription)
  );

  pageTitle$ = this.store.pipe(select(selectors.selectPageTitle));

  summary$ = this.store.pipe(select(selectors.selectSummary));

  constructor(private store: Store<any>) {}

  add(data: ApplicationsApi.AddRequestData) {
    this.store.dispatch(new actions.AddRequestAction(data));
  }

  removeFile(fieldName: string, fileGuid?: string) {
    this.store.dispatch(
      new actions.RemoveFileRequestAction({ fieldName, fileGuid })
    );
  }
}
