import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ElmRecaptchaBrandingComponent } from './recaptcha-branding';
import { ElmRecaptchaComponent } from './recaptcha.component';
import { Theme } from './recaptcha.models';
import { ElmRecaptchaService } from './recaptcha.service';

@Directive({
  selector: 'button[elmRecaptchaTrigger], [elmRecaptchaTrigger]',
  exportAs: 'elmRecaptchaTrigger'
})
export class ElmRecaptchaTriggerDirective implements OnInit, OnDestroy {
  @Input() theme: Theme = 'light';

  @Input() disableRecaptcha = false;

  @Output() recaptchaVerified = new EventEmitter<void>();

  @Output() recaptchaFailed = new EventEmitter<void>();

  private _recaptchaWidget!: ComponentRef<ElmRecaptchaComponent>;

  private _listenRecaptchaSuccess?: Subscription;

  private _listenRecaptchaConnectionError?: Subscription;

  verifying = false;

  private _verified = false;

  constructor(
    private recaptcha: ElmRecaptchaService,
    private cdr: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  ngOnInit() {
    if (this.disableRecaptcha) {
      return;
    }

    this._recaptchaWidget = this._insertRecaptchaWidget();
    this._insertRecaptchaBranding();
    this._listenRecaptchaSuccess =
      this._recaptchaWidget.instance.recaptchaSuccess
        .pipe(
          tap(() => this._setVerifying(true)),
          switchMap(token => this.recaptcha.verify(token))
        )
        .subscribe(
          valid => {
            if (valid) {
              this.recaptchaVerified.emit();
              this._verified = true;
            } else {
              this.recaptchaFailed.emit();
            }

            this._setVerifying(false);
          },
          () => {},
          () => this._setVerifying(false)
        );

    // if there is network error on recaptcha side
    // we want to allow  student to submit
    this._listenRecaptchaConnectionError =
      this._recaptchaWidget.instance.recaptchaError.subscribe(() => {
        this._verified = true;
        this._setVerifying(false);
        this.recaptchaVerified.emit();
      });
  }

  verify() {
    if (this.disableRecaptcha) {
      this.recaptchaVerified.emit();
      return;
    }
    /**
     * if recaptcha has already been verified
     * verification is not executed again
     */
    if (!this._verified) {
      this._recaptchaWidget.instance.execute();
    } else {
      this.recaptchaVerified.emit();
    }
  }

  /**
   * create recaptcha widget and insert after recaptcha trigger element
   */
  private _insertRecaptchaWidget() {
    const factory = this.resolver.resolveComponentFactory(
      ElmRecaptchaComponent
    );
    const component = this.container.createComponent(factory);

    return component;
  }

  private _insertRecaptchaBranding() {
    const factory = this.resolver.resolveComponentFactory(
      ElmRecaptchaBrandingComponent
    );
    const component = this.container.createComponent(factory);
    component.instance.theme = this.theme;
  }

  private _setVerifying(value: boolean) {
    this.verifying = value;
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    this._listenRecaptchaSuccess?.unsubscribe();
    this._listenRecaptchaConnectionError?.unsubscribe();
  }
}
