import {
  TasksApi_GetTaskQuery as GetTask,
  TasksApi_GetTaskQueryVariables as GetTaskVariables,
  Task
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { GetTask, GetTaskVariables, Task };

export const getTask = gql`
  query TasksApi_GetTask($guid: String!) {
    task(input: { guid: $guid }) {
      guid
      name
      due_datetime
      type_guid
      status_guid
      priority_guid
      related_to
      assigned_to
      description
      parent_task_guid
      notes {
        item_id
        body
        author_id
        created_at
        updated_at
      }
      subscribers
      created_by
      created_at
      updated_at
      subtasks {
        guid
        name
        due_datetime
        type_guid
        status_guid
        priority_guid
        related_to
        assigned_to
        parent_task_guid
        category
        related_user_can_see_task
        assignee_can_complete
      }
      category
      related_user_can_see_task
      assignee_can_complete
      parentTask {
        guid
        name
      }
    }
  }
`;
