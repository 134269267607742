<ng-container *ngIf="row">
  <elm-pg-billboard-form-centered
    [pageGuid]="row.pageGuid"
    [title]="row.title"
    [subtitle]="row.subtitle"
    [background]="row.background"
    [elements]="row.elements"
    [aligned]="row.aligned"
    [stripeCentered]="row.stripeCentered"
    [stickyHeader]="row.header?.stickyHeader"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>

    <elm-pg-header
      [logoSize]="row.logoSize"
      [aligned]="row.header?.aligned"
      [backgroundColor]="row.header?.backgroundColor"
    >
      <elm-pg-image-placeholder
        *ngIf="row.elements?.logo !== false"
        editable="logo"
        [image]="row.logo"
        [alt]="row.logoAlt"
        placeholder="Your logo here"
        [link]="row.link"
        [ariaLabel]="row.ariaLabel"
      >
      </elm-pg-image-placeholder>

      <elm-pg-navigation
        *ngIf="row.elements?.navigation && row.navigation; let navigation"
        [menuItemsColor]="navigation.menuItemsColor"
        [menuItemsHoverColor]="navigation.menuItemsHoverColor"
        [items]="navigation.items"
      >
        <a
          elmPgBtn
          *ngIf="navigation.showButton && navigation.button; let button"
          [href]="button.route"
          [styling]="button.styling"
          [buttonColor]="button.buttonColor"
          [buttonHoverColor]="button.buttonHoverColor"
          [labelColor]="button.labelColor"
          [labelHoverColor]="button.labelHoverColor"
          [size]="button.size"
          [shape]="button.shape"
          [hasBoxShadow]="button.hasBoxShadow"
          [ariaLabel]="button.ariaLabel"
        >
          {{ button.label }}
        </a>
      </elm-pg-navigation>
    </elm-pg-header>

    <elm-pg-image-placeholder
      editable="image"
      [image]="row.image"
      [alt]="row.imageAlt"
      palceholder="Your image here"
      [link]="row.link"
      [ariaLabel]="row.ariaLabel"
    >
    </elm-pg-image-placeholder>

    <!-- form$ is the entire form -->
    <!-- row.form is only the reference with overriden fields on the component model -->
    <elm-pg-form *ngIf="row.form?.guid; else placeholderForm" [theme]="row.form?.theme">
      <elm-form-state-machine
        editable="form"
        [row]="row"
        [state]="formState$ | async"
        [form]="form$ | async"
        [formData]="formData$ | async"
        [prepopulatedData]="prepopulatedData$ | async"
        [user]="user$ | async"
        (paymentDone)="paymentDone()"
        (formSubmit)="submit($event)"
        (userVerified)="userVerified($event)"
        (prepopulatedEdit)="prepopulatedEdit(rowGuid)"
        (prepopulatedSubmit)="prepopulatedSubmit(rowGuid)"
      >
      </elm-form-state-machine>
    </elm-pg-form>

    <ng-template #placeholderForm> <elm-pg-form-placeholder editable="form"> </elm-pg-form-placeholder> </ng-template>
  </elm-pg-billboard-form-centered>
</ng-container>
