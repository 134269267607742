import { FormsApi } from '../forms';
import { MappingsApi } from '../mappings';
import { NotesApi } from '../notes';
import { TagsApi } from '../tags';
import { UsersApi } from '../users';

export enum AppointmentStatus {
  Scheduled = 'scheduled',
  Attended = 'attended',
  Canceled = 'canceled',
  NoShow = 'no_show'
}

export interface Subfields {
  name: string;
  value: string;
}

export interface Field {
  slug: string;
  name: string;
  value?: string;
  subfields?: Subfields[];
  mapping?: MappingsApi.MappingWithOptions;
}

export interface Appointment {
  guid: string;
  public_uuid: string;
  admin_id: string;
  user_id: string;
  start_date: string;
  status: AppointmentStatus;
  duration: number;
  availability_guid: string;
  appointment_type: string;
  fields: Field[];
  location: string;
  location_type: 'physical' | string;
  notes: NotesApi.Note[];
  updated_at: string;
  created_at: string;
}

export interface AppointmentPublic extends Omit<Appointment, 'admin_id'> {
  admin_id: {
    avatar: UsersApi.UserAvatar;
    email: string;
    first_name: string;
    last_name: string;
    _id: string;
  };
}

export type AppointmentDto = Pick<
  Appointment,
  'user_id' | 'start_date' | 'status' | 'availability_guid'
>;

export interface AppointmentsStats {
  overdue: number;
  today: number;
  upcoming: number;
}

export interface AppointmentsDateStats {
  today: number;
  tomorrow: number;
  thisWeek: number;
  thisMonth: number;
}

export interface Type {
  _id: string;
  _subdom: string;

  guid: string;
  name: string;
  category_guid: string;
  field_guid: string;
  form: FormsApi.FormData;
  description: string;
  public: boolean;
  location: string;
  location_type: AvailabilityLocationType;
  updated_at: string;
  created_at: string;
}

type BaseTypeDto = Omit<
  Type,
  '_id' | '_subdom' | 'guid' | 'form' | 'updated_at' | 'created_at'
>;

export interface TypeDto extends BaseTypeDto {
  form: FormsApi.Field[];
}

export enum AvailabilityType {
  Rolling = 'rolling',
  Fixed = 'fixed',
  Indefinitely = 'indefinitely'
}

export enum AvailabilityLocationType {
  Virtual = 'virtual',
  Physical = 'physical'
}

export interface AvailabilityHours {
  from: string;
  to: string;
}
export interface DailyAvailability {
  enabled: boolean;
  hours: AvailabilityHours[];
}

export interface WeeklyAvailability {
  mon: DailyAvailability;
  tue: DailyAvailability;
  wed: DailyAvailability;
  thu: DailyAvailability;
  fri: DailyAvailability;
  sat: DailyAvailability;
  sun: DailyAvailability;
}

export interface AvailabilityOverride {
  date: string;
  hours: AvailabilityHours[];
}

export enum DeliveryChannel {
  Email = 'email',
  SMS = 'sms'
}

export interface AvailabilityEmailNotification {
  from_email: string;
  from_name: string;
  reply_email: string;
}

export interface AvailabilitySmsNotification {
  from_number: string;
}

export interface AvailabilityNotifications {
  channels: DeliveryChannel[];
  email: AvailabilityEmailNotification;
  sms?: AvailabilitySmsNotification;
}

export interface Availability {
  _subdom: string;
  _id: string;

  guid: string;
  admin_id: string;
  timezone: string;

  name: string;
  slug: string;

  type: AvailabilityType;
  rolling_offset: number; // type === 'rolling'
  schedule_start: string; // type === 'fixed'
  schedule_end: string; // type === 'fixed'

  days_and_times: WeeklyAvailability;
  days_and_times_overrides: AvailabilityOverride[];
  buffering_before: number;
  buffering_after: number;
  slot_duration: number;
  minimum_time_before_start: number;
  max_count_of_events_per_day: number;
  appointment_type: string;
  location_type: AvailabilityLocationType;
  location: string;
  active: boolean;
  description: string;
  notifications: AvailabilityNotifications;
  updated_at: string;
  created_at: string;
}

export type AvailabilityDto = Omit<
  Availability,
  '_subdom' | '_id' | 'guid' | 'admin_id' | 'updated_at' | 'created_at'
>;

/**
 * When user schedule an appointment we get location and location_type from availability
 * and save it on the scheduled appointment itself so we can utilize it on segments.
 * So when admin updates availability location/type we want to allow them to propagate that
 * change to already scheduled appointments by sending `update_existing_scheduled=true`
 */
export type UpdateAvailabilityDto = AvailabilityDto & {
  update_existing_scheduled: boolean;
  admin_id: string;
};

export enum UserCalendarStatus {
  Valid = 'valid',
  Invalid = 'invalid'
}

export interface UserCalendar {
  _id: string;
  _subdom: string;
  guid: string;
  admin_id: string;
  status: UserCalendarStatus;
  url: string;
  update_enabled: boolean;
  updated_at: string;
  created_at: string;
}

export type UserCalendarDto = Pick<UserCalendar, 'update_enabled' | 'url'>;

export interface CalendarSlotsRange {
  year: number;
  month: number;
}

export interface CalendarSlotsDate {
  date: string;
  timezone_type: number;
  timezone: string;
}

export interface CalendarSlot {
  start_date: CalendarSlotsDate;
  end_date: CalendarSlotsDate;
  uid: string;
  broken?: boolean;
}

export enum SettingsType {
  Admin = 'admin',
  Client = 'client'
}
interface BaseSettings {
  _id: string;
  _subdom: string;
  guid: string;
  type: SettingsType;
  updated_at: string;
  created_at: string;
}

export interface UserSettings extends BaseSettings {
  _subdom: undefined; // no subdom actually
  admin_id: undefined; // api does not return anything

  type: SettingsType.Admin;
  values: UserSettingsValue;
  calendar_feature_token: string;
}

export interface ClientSpecificSettings {
  color: string;
  name: string;
  secondary_color: string;
  timezone: string;
}

export interface ClientSettings extends BaseSettings {
  type: SettingsType.Client;
  values: ClientSettingsValue;
  client: ClientSpecificSettings;
}

export interface UserSettingsValue {
  slug: string;
}

interface ClientSettingsValueSocial {
  facebook: string | null;
  twitter: string | null;
  youtube: string | null;
  instagram: string | null;
  linkedin: string | null;
}

export interface ClientSettingsValueFooter {
  text: string | null;
  social: ClientSettingsValueSocial;
}

interface ClientSettingsValueThankYou {
  title: string | null;
  description: string | null;
}

export interface Host {
  _id: string;
  url: string;
}

export type HostDto = Pick<Host, 'url'>;

export interface ClientSettingsValuePrimaryDomain {
  domain: string;
  subdomain: string;
}

export enum MessengerLauncherColor {
  Primary = 'primary',
  Secondary = 'secondary'
}

export interface ClientSettingsValueMessenger {
  active: boolean;
  appearance: {
    launcher_icon_color: MessengerLauncherColor;
  };
}

export interface ClientSettingsValue {
  hosts: Host[];
  default_form: FormsApi.Field[];
  primary_domain: ClientSettingsValuePrimaryDomain;
  header_logo: string | null;
  footer_logo: string | null;
  banner_background: string | null;
  footer: ClientSettingsValueFooter;
  thank_you: ClientSettingsValueThankYou;
  messenger: ClientSettingsValueMessenger;
}

export type UserSettingsDto = Pick<UserSettings, 'values'>;

export type ClientSettingsValueDto = Omit<
  ClientSettingsValue,
  'default_form' | 'hosts' | 'primary_domain'
>;
interface ClientSettingsDtoBase extends Omit<ClientSettings, 'values'> {
  values: ClientSettingsValueDto;
}
export type ClientSettingsDto = Pick<ClientSettingsDtoBase, 'values'>;

export type Settings = UserSettings | ClientSettings;

export type SettingsDto = UserSettingsDto | ClientSettingsDto;

export type Category = TagsApi.Tag;
