import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { find } from 'lodash';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';

@Component({
  selector: 'lum-df-mask',
  templateUrl: './mask.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['mask.component.scss']
})
export class MaskComponent
  extends FieldConfigDirective<DynamicFieldModel>
  implements OnInit
{
  mask: string;

  dropSpecialCharacters = true;

  patterns = {
    A: { pattern: new RegExp('[A-Za-z]') },
    S: { pattern: new RegExp('[a-zA-Z0-9]') },
    // new way of defining mask
    '0': { pattern: new RegExp('[0-9]') },
    // deprecated: support how we used to define mask
    '9': { pattern: new RegExp('[0-9]') }
  };

  ngOnInit() {
    const config = findMaskConfig(this.model);
    if (config) {
      this.mask = config.mask;
      this.dropSpecialCharacters = !!config.dropSpecialCharacters;
    }
  }
}

function findMaskConfig(model: DynamicFieldModel) {
  const maskValidation = find(
    model.validations,
    validation => validation.type === 'mask'
  );
  if (maskValidation) {
    return {
      mask: maskValidation.mask,
      dropSpecialCharacters: !maskValidation.save_masked_value
    };
  }
  return null;
}
