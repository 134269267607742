import { DocumentsApi } from '../documents';
import { LabelsApi } from '../labels';
import { PhoneApi } from '../phone';
import { UsersApi } from '../users';

export interface AuthUser {
  email: string;
  password: string;
}

export interface Account {
  created_at?: string;
  updated_at?: string;
  last_login?: string;
  first_loggedin_at?: string;
  session_count?: number;
  ask_for_password?: boolean;
  properties: AdminProperties;
  id: string;
  permissions?: Permission[];
  groups?: { _id: string; name: string }[];
  labels?: LabelsApi.Label[];
  tos?: UsersApi.UserTOS;
  document_types?: DocumentsApi.ApiDocumentType[];
  dashboard_start_page?: string;
}

export interface SharedAccountProperties {
  email: string;
  first_name: string;
  last_name: string;
  avatar?: UsersApi.UserAvatar;
}

// TODO: clean this up, this one is shared between internal and external logins, we should tell BE to clean that up
export interface UserProperties extends SharedAccountProperties {
  preferred_name?: string;
  gender?: string;
  term?: string;
  major?: string;
  campus?: string;
  app_major?: string;
  dob?: string;
  prefered_major?: string;
  intended_major?: string;
  intended_term?: string;
  intended_degree?: string;
  intended_campus?: string;
  active_campus?: string;
  [key: string]: any;
}

interface AdminRelationships {
  relation: string;
  type: string;
  target: string;
}

export interface AdminProperties extends SharedAccountProperties {
  title?: string; // Title of user
  visibility_groups?: string[];
  restrict_people?: boolean;
  restrict_documents?: boolean;
  groups?: { name: string; _id: string }[];
  user_relationships?: AdminRelationships[];
  dashboard_start_page?: string;
  cell_phone?: PhoneApi.Phone;
  home_phone?: PhoneApi.Phone;
}

export interface Permission {
  slug: string;
  name: string;
}

export interface PermissionMap {
  [slug: string]: Permission;
}

export interface MfaLoginResponse {
  mfa_challenge: boolean;
}

export interface LockerLoginResponse {
  token: string;
}

export type LoginResponse = MfaLoginResponse | LockerLoginResponse;

export const isMfaLoginResponse = (
  response: LoginResponse
): response is MfaLoginResponse => {
  return (response as MfaLoginResponse).mfa_challenge === true;
};

export interface ImpersonateUserParams {
  application_guid?: string;
  registration_id?: string;
}
