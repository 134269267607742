import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TasksApi } from '@element451-libs/models451';
import { catchApolloError } from '@element451-libs/utils451/rxjs';
import { Apollo } from 'apollo-angular';
import { map, take } from 'rxjs';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { responseData } from '../rxjs';
import { Api451Done, ElmResponse } from '../shared';
import { GetQueueStats, getQueueStats } from './queries/get-queue-stats.query';
import { TasksApiModule } from './tasks-api.module';

type R<T> = ElmResponse<T>;

@Injectable({
  providedIn: TasksApiModule
})
export class TaskQueuesApiService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  getAll() {
    return this.http
      .get<R<TasksApi.Queue[]>>(this.url(`people/tasks/settings/queues`))
      .pipe(responseData);
  }

  getStats() {
    return this.apollo
      .query<GetQueueStats>({
        query: getQueueStats,
        fetchPolicy: 'network-only'
      })
      .pipe(
        catchApolloError(result => result?.queueStats),
        map(result => result.data?.queueStats),
        take(1)
      );
  }

  create(createDto: TasksApi.CreateQueueDto) {
    return this.http
      .post<R<TasksApi.Queue>>(
        this.url(`people/tasks/settings/queues`),
        createDto
      )
      .pipe(responseData);
  }

  update(guid: string, updateDto: TasksApi.UpdateQueueDto) {
    return this.http
      .put<R<TasksApi.Queue>>(
        this.url(`people/tasks/settings/queues/${guid}`),
        updateDto
      )
      .pipe(responseData);
  }

  delete(guid: string) {
    return this.http
      .delete<R<Api451Done>>(this.url(`people/tasks/settings/queues/${guid}`))
      .pipe(responseData);
  }
}
