import { APP_ID, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserDocumentRefModule } from '@element451-libs/utils451/document';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { App451ErrorHandler } from './core/error.service';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    BrowserDocumentRefModule,

    CoreModule.forRoot(),

    AppRoutingModule,
    HammerModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: App451ErrorHandler
    },
    { provide: APP_ID, useValue: 'app451' }
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
