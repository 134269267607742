import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class WebpushSubscriptionsApiModule {
  static forRoot() {
    return {
      ngModule: WebpushSubscriptionsApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: WebpushSubscriptionsApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'WebpushSubscriptionsApiModule');
  }
}
