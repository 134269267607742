import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AppointmentsApi,
  AppointmentsPublicApi
} from '@element451-libs/models451';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ElmResponse2, searchToParams } from '../shared';
import { AppointmentSettingsApiService } from './appointment-settings-api.service';

// shorthand
type R<T> = ElmResponse2<T>;

@Injectable()
export class AppointmentsPublicApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory,
    private appointmentSettingsApi: AppointmentSettingsApiService
  ) {}

  getUsers(filters: AppointmentsPublicApi.UsersFilters) {
    const params = searchToParams(filters);
    return this.http.get<R<AppointmentsPublicApi.User[]>>(
      this.url(`appointments/availability/allUsers`),
      { params }
    );
  }

  getAvailabilities(
    adminSlug: string,
    filters: AppointmentsPublicApi.AvailabilityFilters
  ) {
    const params = searchToParams(filters);
    return this.http.get<
      R<{
        user: AppointmentsPublicApi.User;
        availabilities: AppointmentsPublicApi.AvailabilityInfo[];
      }>
    >(this.url(`appointments/availability/${adminSlug}`), { params });
  }

  getAvailability(adminSlug: string, availabilitySlug: string) {
    return this.http.get<
      R<{
        user: AppointmentsPublicApi.User;
        availability: AppointmentsApi.Availability;
      }>
    >(
      this.url(
        `appointments/availability/user/${adminSlug}/availability/${availabilitySlug}`
      )
    );
  }

  getAppointment(publicUuid: string) {
    return this.http.get<R<AppointmentsPublicApi.ScheduledAppointment>>(
      this.url(`appointments/public/scheduled/${publicUuid}`)
    );
  }

  getBusySlots(
    adminSlug: string,
    filters: AppointmentsPublicApi.BusySlotsFilters
  ) {
    const params = searchToParams(filters);
    return this.http.get<R<AppointmentsPublicApi.AppointmentInfo[]>>(
      this.url(`appointments/public/scheduled/admin/${adminSlug}`),
      { params }
    );
  }

  getUserSettingsBySlug(slug: string) {
    return this.appointmentSettingsApi.getUserSettingsBySlug(slug);
  }

  scheduleAnAppointment(payload: AppointmentsPublicApi.ScheduleAppointmentDto) {
    return this.http.post<R<AppointmentsApi.Appointment>>(
      this.url(`appointments/public/scheduled`),
      payload
    );
  }

  updateAnAppointment(
    publicUuid: string,
    update:
      | { fields: AppointmentsPublicApi.FieldDto[] }
      | { status: AppointmentsApi.AppointmentStatus }
  ) {
    return this.http.put<R<AppointmentsApi.Appointment>>(
      this.url(`appointments/public/scheduled/${publicUuid}`),
      update
    );
  }
}
