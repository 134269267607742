import {
  TasksApi_SearchTasksQuery as SearchTasks,
  TasksApi_SearchTasksQueryVariables as SearchTasksVariables,
  Task,
  TasksFiltersInput
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { SearchTasks, SearchTasksVariables, Task, TasksFiltersInput };

export const searchTasks = gql`
  query TasksApi_SearchTasks(
    $q: String!
    $offset: Int!
    $limit: Int!
    $filters: TasksFiltersInput!
    $sort: String
    $queue: String
  ) {
    searchTasks(
      input: {
        q: $q
        offset: $offset
        limit: $limit
        filters: $filters
        sort: $sort
        queue: $queue
      }
    ) {
      tasks {
        guid
        name
        due_datetime
        type_guid
        status_guid
        status
        priority_guid
        related_to
        assigned_to
        category
        related_user_can_see_task
        assignee_can_complete
        parent_task_guid
      }
      hasNextPage
      hasPrevPage
      limit
      page
    }
  }
`;
