import { ApplicationsApi } from '@element451-libs/models451';
import { progressIdFactory } from '../shared/progress-id-factory';
import { appendSnapAppSuffix } from '../snap-app/snap-app.helper';
import { Section, Step } from './steps.models';

export function normalizeStep(
  step: ApplicationsApi.Step,
  registrationId: string,
  { isSnapApp }: { isSnapApp: boolean } = { isSnapApp: false }
): Step {
  const {
    _id,
    name,
    segment,
    progress: { progress_guid },
    sidebar_content
  } = step;

  const id = isSnapApp ? appendSnapAppSuffix(_id) : _id;
  const sections = normalizeSections(
    step.subsections,
    isSnapApp,
    registrationId
  );
  const isRepeater = !!step.repeater;

  return {
    id,
    name,
    segment,
    sections,
    repeater: isRepeater,
    progress_guid: progressIdFactory(progress_guid, registrationId),
    sidebar_content
  };
}

export function normalizeSections(
  sections: ApplicationsApi.Section[],
  isSnapApp: boolean,
  registrationId: string
): Section[] {
  const sortedSections = sortStepSections(sections);
  return sortedSections.map(section => {
    const {
      _id,
      name,
      segment,
      form,
      progress: { progress_guid },
      min,
      max,
      title,
      sidebar_content,
      index_weight
    } = section;

    const id = isSnapApp ? appendSnapAppSuffix(_id) : _id;
    const formGuid = isSnapApp ? appendSnapAppSuffix(form.guid) : form.guid;
    const repeater = !!section.repeater;

    return {
      actionTitle: section['action-title'],
      emptyDescription: section['empty-description'],
      formGuid,
      id,
      itemDescription: section['item-description'],
      itemTitle: section['item-title'],
      max,
      min,
      name,
      progress_guid: progressIdFactory(progress_guid, registrationId),
      repeater,
      segment,
      title,
      sidebar_content,
      index_weight
    };
  });
}

const sortStepSections = (sections: ApplicationsApi.Section[]) =>
  [...sections].sort((a, b) => a.index_weight - b.index_weight);
