import { ConditionsApi } from '../conditions';
import { FilesApi } from '../files';
import { PushNotificationsApi } from '../push-notifications';
import { UsersApi } from '../users';

interface Author extends UsersApi.ApiPerson {
  email: string;
}

export enum CommunicationType {
  Regular = 'regular',
  Dynamic = 'dynamic',
  Multivariate = 'multivariate'
}
export interface EmailPreview {
  email: {
    body: string;
  };
}
export interface Preview {
  valid: boolean;
  type: CommunicationType;
  channels: Channels;
  email: {
    body: string;
    from_email: string;
    from_name: string;
    preview_text: string;
    subject: string;
  };
  sent_at: string;
  settings: Settings;
  status: string;
  variate_content: VariateContent[];
  sms: SMS;
  categories_available?: string[];
  dynamic_content?: {
    conditions: Condition[];
    variants: DynamicContentVariant[];
  };
}
export interface EmailPreviewForUser {
  _id: string;
  content_type: string;
  _subdom: string;
  guid: string;
  updated_at: string;
  created_at: string;
  subject: string;
  from_email: string;
  reply_email: string;
  from_name: string;
  preview_text: string;
  body: string;
  tokens: Record<string, string>;
  from: {
    email: string;
    name: string;
    reply_to: string;
  };
  to: {
    email: string;
    name: string;
  };
  conditions: string[];
}
export interface SmsMedia {
  type: string;
  value: string;
}
export interface SMS {
  from_number: string;
  sms: DynamicContentVariationSms;
  content: string;
  media?: SmsMedia[];
}

export interface DynamicContentVariant {
  variable: string;
  variations: DynamicContentVariation[];
}

export type DynamicContentVariationValue = string | DynamicContentVariationSms;
export interface DynamicContentVariation {
  condition: string;
  value: DynamicContentVariationValue;
}

export interface DynamicContentVariationSms {
  content: string;
  file: string;
  opt_out_text?: string;
}

export interface PreviewMultivariateCategories {
  ab: string;
}

export interface PreviewDynamicCategories {
  dynamic: string[];
}

export type PreviewCategories =
  | (PreviewMultivariateCategories & PreviewDynamicCategories)
  | PreviewMultivariateCategories
  | PreviewDynamicCategories;

export interface VariateContent {
  variable: AbVariable;
  variations: AbVariation[];
  quantity: number;
}

export interface VariateContentWinner {
  time: string;
  action: {
    type: string;
    time?: string;
  };
  criteria: {
    strategy: string;
    time?: string;
  };
}

/**
 * Campaign Templates
 */

export interface TemplateLayout {
  icon: string;
  description: string;
  checked: boolean;
}

export interface TemplateThumbnail {
  guid: string;
  url: string;
  transformations: FilesApi.FileTransformations;
}

interface TemplateBase {
  name: string;
  layouts: TemplateLayout;
  guid: string;
  updated_at: string;
  created_at: string;
  thumbnail: TemplateThumbnail;
}

export interface TemplateTag {
  guid: string;
  name: string;
  value: string;
}

export enum TemplateType {
  Saved = 'saved',
  Basic = 'basic'
}

export interface Template extends TemplateBase {
  author: Author;
  folder?: TemplateTag;
  tags?: string[];
}

export interface TemplatesResponse extends TemplateBase {
  author: Author;
  folder?: TemplateTag;
  tags?: TemplateTag[];
}

export interface TemplateExpanded extends TemplateBase {
  tags?: string[];
  type: TemplateType;
  category: 'email' | string;
  content: {
    content_type: 'html' | string;
    body: string;
    body_template: EmailContentBodyTemplate;
  };
}

/* Campaigns Communications */

export enum AudienceType {
  Segment = 'segment',
  Workflow = 'workflow'
}

export interface Audience {
  type: AudienceType | null;
  segment?: string | null; // for type === 'workflow', segment property doesn't exist
  segment_name?: string;
}

export enum Priority {
  Med = 'med',
  Transactional = 'transaction'
}

export enum Status {
  Draft = 'draft',
  Sent = 'sent',
  Ready = 'ready',
  Stopped = 'stopped',
  Error = 'error',
  Scheduled = 'scheduled'
}

export enum PreferredTimeToSendCommunication {
  UserPreferredTime = 'user_preferred_time',
  SpecificTimeInUserTimezone = 'specific_time_user',
  SpecificTimeInConfiguredTimezone = 'specific_time_configured',

  // uppercase because they are constants on PHP
  Morning = 'MORNING',
  Day = 'DAY',
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING',
  Night = 'NIGHT',
  Latenight = 'LATENIGHT'
}

export enum DeliveryChannel {
  Email = 'email',
  SMS = 'sms',
  Push = 'push'
}

export interface Channels {
  [DeliveryChannel.Email]: boolean;
  [DeliveryChannel.SMS]: boolean;
  [DeliveryChannel.Push]: boolean;
}

export enum EmailCategory {
  Dynamic = 'dynamic',
  AbTesting = 'ab'
}

export interface EmailContentBodyTemplateRowContent {
  variant: string;
  category?: EmailCategory;
  columns: {
    attributes: {
      style: any;
    };
    size: number;
    [key: string]: any;
  }[];
}

export interface EmailContentBodyTemplateRow {
  id: string;
  indexWeight: number;
  type: string;
  content: EmailContentBodyTemplateRowContent[];
}

export interface EmailContentBodyTemplate {
  title: string;
  theme: string;
  name: string;
  isEmail: boolean;
  defaultHasContainer: boolean;
  attributes: {
    style: any;
  };
  rows: EmailContentBodyTemplateRow[];
}

export type EmailBuilderChange = Pick<
  EmailContentBodyTemplate,
  'attributes' | 'rows'
>;

export interface EmailContent {
  body_template: EmailContentBodyTemplate;
  content_type: string;
  created_at: string;
  guid: string;
  updated_at: string;
  _id: string;
  _subdom: string;

  from_email?: string;
  from_name?: string;
  preview_text?: string;
  reply_email?: string;
  subject?: string;
  cc_emails?: string;
  bcc_emails?: string;
}

export interface SmsContent {
  content: string;
  from_number: string;
  sms?: DynamicContentVariationSms;
  media?: SmsMedia[];
  opt_out_text?: string;
}

export enum PushType {
  General = 'general'
}

export interface PushContent {
  push_title: string;
  push_content: string;
  push_parameters: {
    link: string;
  };
}

export interface Content {
  notification: string;
}

export interface ContentExpanded extends Content {
  notification: string;
  email: EmailContent;
  sms: SmsContent;
  push: PushContent;
}

export enum SendSettingsType {
  Immediate = 'immediate',
  Scheduled = 'scheduled'
}

export interface SendSettings {
  type: SendSettingsType;
  time?: string; // for type === 'immidiate', time property doesn't exist
}

export enum SendToOptions {
  PrimaryEmail = 'primary',
  SecondaryEmail = 'secondary',
  SchoolEmail = 'school',
  TokenBasedEmail = 'token'
}

export const SEND_TO_OPTION_LABELS: Record<SendToOptions, string> = {
  [SendToOptions.PrimaryEmail]: 'Primary Email',
  [SendToOptions.SecondaryEmail]: 'Secondary Email',
  [SendToOptions.SchoolEmail]: 'School Email',
  [SendToOptions.TokenBasedEmail]: 'Token Based Email'
};

export interface Settings {
  title: string;
  bounce_email: string;
  push_type: PushType;
  /**
   * null is same as primary
   */
  send_to: SendToOptions | null;
  send_to_token?: string;
}
export interface Tracking {
  opens: boolean;
  clicks: boolean;
}
export interface ChannelCount {
  total: number;
  [key: string]: number;
}
export interface SendCount {
  segment_total: number;
  total_recipients?: number;
  email?: ChannelCount;
  sms?: ChannelCount;
}

export interface Condition {
  id: string;
  name: string;
  description: string;
  active: boolean;
  index_weight: number;
  rules: ConditionsApi.Condition[];
}

export enum AbVariable {
  FromName = 'from_name',
  Subject = 'subject',
  Content = 'content',
  PreviewText = 'preview_text'
}

export type ConditionPayload = Pick<
  Condition,
  'name' | 'description' | 'active'
>;

export interface VariationValue {
  content: string;
  file: string; // file guid
  opt_out_text?: string;
}

export interface PushParameters {
  key: PushNotificationsApi.PushNotificationParameterKey;
  value: string;
}

export interface DynamicVariation {
  condition: 'default' | string;
  value: string | VariationValue | PushParameters[];
}

export interface DynamicArrayVariation {
  condition: 'default' | string;
  value: PushParameters[];
}

export interface AbVariation {
  value: string | VariationValue;
  metadata: any;
}

export enum VariantVariable {
  Subject = 'subject',
  FromEmail = 'from_email',
  ReplyEmail = 'reply_email',
  FromName = 'from_name',
  PreviewText = 'preview_text',
  Content = 'content',
  FromNumber = 'from_number',
  Sms = 'sms',
  CCEmails = 'cc_emails',
  BCCEmails = 'bcc_emails',
  // Push
  PushTitle = 'push_title',
  PushContent = 'push_content',
  PushParameters = 'push_parameters'
}

export interface Variant {
  variable: VariantVariable;
  variations: DynamicVariation[];
}

export interface ArrayVariant {
  variable: VariantVariable.PushParameters;
  variations: DynamicArrayVariation[];
}

export interface DynamicContent {
  conditions: Condition[];
  variants: (Variant | ArrayVariant)[];
}

interface CommunicationBase {
  audience: Audience;
  channels: Channels;
  created_at: string;
  preview_key: string;
  priority: Priority | null;
  started_at?: any; // not relevant to our models
  send_settings: SendSettings;
  sent_at: string | null;
  settings: Settings;
  status: Status;
  tracking: Tracking;
  type: CommunicationType;
  updated_at: string;
  _subdom: string;
  guid: string;
  utm_params?: UtmParams;
}

export interface Communication extends CommunicationBase {
  archive_url: string;
  author: string; // user guid
  content: Content;
  error?: any | null;
  folder?: string;
  process_id: string;
  reserved_at?: any; // not relevant to our models
  send_count: SendCount;
  sent_by?: string; // user guid
  stage?: number;
  tags?: string[];
}

export interface VariateContentExpanded {
  test_percentage: number;
  variants: VariateContent[];
  winner: VariateContentWinner;
}

export interface UtmParams {
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
}

export interface CommunicationExpanded extends CommunicationBase {
  author: Author;
  content: ContentExpanded;
  dynamic_content?: DynamicContent;
  folder: TemplateTag;
  tags?: TemplateTag[];
  valid: boolean;
  first_sent_at?: null;
  sent_by: Author;
  // on AB communications
  ab_winner?: string;
  variate_content?: VariateContentExpanded;
}

export interface CommunicationFilterItem {
  name: string;
  value: string;
}

export interface CommunicationFilter {
  handle: string;
  items: CommunicationFilterItem[];
  name: string;
  scope: string;
}

interface CommunicationPreviewEmail {
  email: string[];
}

interface CommunicationPreviewSms {
  sms: string[];
}

interface CommunicationPreviewSegment {
  segment: string;
}

export type CommunicationPreviewTarget =
  | CommunicationPreviewEmail
  | CommunicationPreviewSms
  | CommunicationPreviewSegment
  | (CommunicationPreviewSms & CommunicationPreviewEmail);

export type CommunicationPreviewChannel = Partial<
  [DeliveryChannel.Email, DeliveryChannel.SMS, DeliveryChannel.Push]
>;

export interface CommunicationPreviewPayload {
  channels: CommunicationPreviewChannel;
  target: CommunicationPreviewTarget;
}

export interface CreateCommunicationPayload
  extends Pick<
    CommunicationExpanded,
    'channels' | 'type' | 'audience' | 'variate_content'
  > {
  settings: {
    title: string;
  };
}

export interface CreateCommunicationResponse {
  guid: string;
  preview_key: string;
}

// Analytics

export enum EmailOpensBy {
  Software = 'software',
  Device = 'device'
}

export interface EmailOpens {
  total: number;
  unique: number;
  variant?: 'default' | AbVariable;
}
export interface SoftwareEmailOpens extends EmailOpens {
  [EmailOpensBy.Software]: string;
}
export interface DeviceEmailOpens extends EmailOpens {
  [EmailOpensBy.Device]: string;
}

export interface Analytic {
  ab_winner?: 'default' | AbVariable;
  _subdom: string;
  guid: string;
  name: string;
  type: CommunicationType;
  status: Status;
  channels: Channels;
  overall_sending: AnalyticOverallSending;
  stats: AnalyticsStats;
  sending: AnalyticSending;
  segment: AnalyticSegment;
}

export interface AnalyticSegment {
  name: string;
  guid: string;
}

export interface AnalyticSending {
  sent_at: string;
  started_at: string;
  total_recipients: number;
}

export interface AnalyticOverallSending {
  sent_by: UsersApi.ApiPerson;
  total_in_segment: number;
  sms_sent_total: number;
  email_sent_total: number;
  sms_delivered_total: number;
  email_delivered_total: number;
}

export interface AnalyticsStats {
  general: {
    email?: AnalyticsEmailStats;
    sms?: AnalyticsSmsStats;
  };
  variations?: {
    email?: {
      [key: string]: AnalyticsEmailStats;
    };
  };
}

export interface CustomComponent {
  _id: string;
  guid: string;
  _subdom: string;
  name: string;
  author_id: string;
  component: string;
  updated_at: string;
  created_at: string;
}

export interface AnalyticsSmsStats {
  sends: {
    unique: number;
  };
  clicks: {
    total: number;
    unique: number;
    overall_through: number;
  };
  delivered: {
    total: number;
    unique: number;
    overall_rate: number;
  };
  bounces: {
    total: 12;
    unique: 12;
  };
}

export interface AnalyticsEmailStats extends AnalyticsSmsStats {
  opens: {
    total: number;
    unique: number;
    overall_rate: number;
  };
  complaints: {
    total: number;
    unique: number;
  };
  unsubscribes: {
    total: number;
    unique: number;
  };
  variant_id?: 'default' | AbVariable;
}

export interface Recipients {
  action: string;
  day_date: number;
  month_date: number;
  total: number;
  unique: number;
  week_date: number;
  year_date: number;
}

export interface ValidationMessage {
  type: 'error' | string;
  scope: string;
  message: string;
}
export interface ValidationResponse {
  complete: boolean;
  messages: ValidationMessage[];
}

export enum CommunicationTypeFilter {
  AbTesting = 'ab_testing',
  Dynamic = 'dynamic',
  Regular = 'regular'
}

export enum PerformanceType {
  positive = 'positive',
  average = 'average',
  negative = 'negative'
}

export enum Tone {
  Professional = 'professional',
  Friendly = 'friendly',
  Exciting = 'exciting',
  Urgent = 'urgent'
}
export interface CampaignsAnalytics {
  _id: string;
  etl_tstamp?: string;
  campaign_guid?: string;
  action?: string;
  user?: UsersApi.Person;
}

export interface RelatedWorkflow {
  guid: string;
  name: string;
}

export const UntaggedId = 'UNTAGGED';

export enum StatsDateFilterRange {
  Today = 'today',
  Yesterday = 'yesterday',
  AllTime = 'all_time',
  PastWeek = 'past_week'
}
