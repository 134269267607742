import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  ViewChild
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'elm-dialog',
  templateUrl: './elm-dialog.component.html',
  styleUrls: ['./elm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmDialogComponent {
  @ViewChild('elmDialogHeader', { static: true }) header: ElementRef;
  @ViewChild('elmDialogContent', { static: true }) content: ElementRef;
  @ViewChild('elmDialogActions', { static: true }) actions: ElementRef;

  @ContentChild(MatTabGroup) tabs: MatTabGroup;
  @ContentChild(MatStepper) stepper: MatStepper;

  @HostBinding('class.elm-dialog-with-header-controls')
  get hasHeaderControls() {
    return !!this.tabs || !!this.stepper;
  }

  @HostBinding('class.elm-dialog-no-content')
  get hasContent() {
    return !this.content.nativeElement.children.length || this.loading;
  }

  @Input() loading = false;
  @Input() titleBorder = true;
  @Input() actionsBorder = true;
  @Input() displayCloseButton = false;

  get hasHeader() {
    return !!this.header.nativeElement.children.length;
  }

  get hasActions() {
    return !!this.actions.nativeElement.children.length;
  }
}
