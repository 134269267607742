import {
  TasksTemplatesAPI_SubtasksTemplatesQuery as SubtasksTemplates,
  TasksTemplatesAPI_SubtasksTemplatesQueryVariables as SubtasksTemplatesVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getSubtasksTemplates = gql<
  SubtasksTemplates,
  SubtasksTemplatesVariables
>`
  query TasksTemplatesAPI_SubtasksTemplates($parentTaskGuid: String!) {
    getSubtasksTemplates(input: { parentGuid: $parentTaskGuid }) {
      guid
      name
      description
      type_guid
      status_guid
      priority_guid
      due_datetime_type
      due_datetime
      due_days
      assigned_to
      category
      related_user_can_see_task
      assignee_can_complete
    }
  }
`;
