import { Action } from '@ngrx/store';

import { ApplicationsApi } from '@element451-libs/api451';

export const enum RECOMMEND_ACTIONS {
  ADD_REQUEST = '[Recommend] Request Add',
  ADD_SUCCESS = '[Recommend] Success Add',
  ADD_FAIL = '[Recommend] Fail Add',

  REMOVE_FILE_REQUEST = '[Recommend] Request Remove File',
  REMOVE_FILE_SUCCESS = '[Recommend] Success Remove File',
  REMOVE_FILE_FAIL = '[Recommend] Fail Remove File',

  ADD_FILE_SUCCESS = '[Recommend] Success Add File'
}

export class AddRequestAction implements Action {
  readonly type = RECOMMEND_ACTIONS.ADD_REQUEST;

  constructor(public payload: ApplicationsApi.AddRequestData) {}
}

export class AddSuccessAction implements Action {
  readonly type = RECOMMEND_ACTIONS.ADD_SUCCESS;

  constructor(
    public payload: { documents: ApplicationsApi.RecommedationDocuments }
  ) {}
}

export class AddFailAction implements Action {
  readonly type = RECOMMEND_ACTIONS.ADD_FAIL;

  constructor(public payload: any) {}
}

export class RemoveFileRequestAction implements Action {
  readonly type = RECOMMEND_ACTIONS.REMOVE_FILE_REQUEST;

  constructor(public payload: { fieldName: string; fileGuid?: string }) {}
}

export class RemoveFileSuccessAction implements Action {
  readonly type = RECOMMEND_ACTIONS.REMOVE_FILE_SUCCESS;

  constructor(public payload: { fieldName: string; fileGuid?: string }) {}
}

export class RemoveFileFailAction implements Action {
  readonly type = RECOMMEND_ACTIONS.REMOVE_FILE_FAIL;

  constructor(
    public payload: { fieldName: string; fileGuid?: string; error: any }
  ) {}
}

export class AddFileSuccessAction implements Action {
  readonly type = RECOMMEND_ACTIONS.ADD_FILE_SUCCESS;

  constructor(
    public payload: {
      /**
       * apiUrl necessary because the files being returned do not have public URL so we need to recreate it
       */
      apiUrl: string;
      fieldName: string;
      currentAllDocuments: ApplicationsApi.RecommedationDocuments;
    }
  ) {}
}
export type RecommendAction =
  | AddRequestAction
  | AddSuccessAction
  | AddFailAction
  | RemoveFileRequestAction
  | RemoveFileSuccessAction
  | RemoveFileFailAction
  | AddFileSuccessAction;
