export const InsightsDashboards = {
  Tasks: {
    slug: 'element451_tasks',
    id: '5f626836-3ca6-44e2-9790-9bd013c59898'
  },
  Funnel: {
    slug: 'element451_funnel',
    id: 'd3cd3c6d-225c-4c0e-97cc-0df4dafe8c81'
  },
  Events: {
    slug: 'element451_events',
    id: 'f16b389f-87f8-4555-8f08-336872d5a02f'
  },
  Campaigns: {
    slug: 'element451_campaigns',
    id: '23089f3c-ac73-4552-b4d0-20ec893f706e'
  },
  Sources: {
    slug: 'element451_sources',
    id: '00698e85-4359-406a-8470-b7fad4a09d1f'
  },
  Conversations: {
    slug: 'element451_conversations',
    id: '893b2ffb-0fc8-448f-89a8-a4a2a5740568'
  },
  WebActivities: {
    slug: 'element451_web_activities',
    id: 'f05aa441-8779-4bcf-b695-43f9445fa033'
  },
  Management: {
    slug: 'element451_management',
    id: 'ee47e887-322c-4ffb-b2f6-a40c32b83650'
  },
  Appointments: {
    slug: 'element451_appointments',
    id: '89449dd4-0d2b-4231-a060-552e3d9a92c1'
  },
  DataHygiene: {
    slug: 'element451_data_hygiene',
    id: '2c2d87aa-d556-4d37-9395-40e7822f63fe'
  },
  Payments: {
    slug: 'element451_payments',
    id: '39ad4afa-e47d-4bb5-9159-3a869327bfa1'
  },
  Applications: {
    slug: 'element451_applications',
    id: '8b3d4cdd-631d-4bde-9550-726ab8685a5e'
  },
  Discovery: {
    slug: 'element451_discovery',
    id: 'eebef473-d945-462c-982e-52c931a19fab'
  }
};

const table = Object.values(InsightsDashboards).reduce(
  (t, d) => {
    t[d.slug] = d.id;
    return t;
  },
  {} as { [slug: string]: string }
);

export function isSharedDashboardId(id: string) {
  return Object.values(table).includes(id);
}

export function getInsightsDashboardIdFromSlug(slug: string): string {
  return table[slug];
}
