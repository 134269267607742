import { PaymentApi } from '@element451-libs/models451';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreditCardData extends PaymentApi.CreditCardData {}

export interface CreditCardDescription {
  name: string;
  pattern: RegExp;
  valid_length: number[];
  icon?: string;
}

export interface CreditCardValidationResult {
  card: CreditCardDescription | null;
  valid: boolean;
  luhnValid: boolean;
  lengthValid: boolean;
}

export const enum CREDIT_CARD_IMG {
  AMEX = 'https://451.imgix.net/shared/cc/amex.png',
  DINERS = 'https://451.imgix.net/shared/cc/diners_club.png',
  JCB = 'https://451.imgix.net/shared/cc/jcb.png',
  VISA = 'https://451.imgix.net/shared/cc/visa.png',
  MASTERCARD = 'https://451.imgix.net/shared/cc/mastercard.png',
  DISCOVER = 'https://451.imgix.net/shared/cc/discover.png'
}

export const enum CVC_IMG {
  AMEX = 'https://451.imgix.net/shared/cvc/hint_amex.png',
  DEFAULT = 'https://451.imgix.net/shared/cvc/hint_default.png'
}

const creditCardImgLookup = new Map([
  [PaymentApi.CREDIT_CARD_TYPE.AMEX, CREDIT_CARD_IMG.AMEX],
  [PaymentApi.CREDIT_CARD_TYPE.DINERS, CREDIT_CARD_IMG.DINERS],
  [PaymentApi.CREDIT_CARD_TYPE.JCB, CREDIT_CARD_IMG.JCB],
  [PaymentApi.CREDIT_CARD_TYPE.VISA, CREDIT_CARD_IMG.VISA],
  [PaymentApi.CREDIT_CARD_TYPE.MASTERCARD, CREDIT_CARD_IMG.MASTERCARD],
  [PaymentApi.CREDIT_CARD_TYPE.DINERS, CREDIT_CARD_IMG.DINERS]
]);

export function getCreditCardImage(type: PaymentApi.CREDIT_CARD_TYPE) {
  return creditCardImgLookup.get(type);
}

export function getCvcImage(type: PaymentApi.CREDIT_CARD_TYPE) {
  return type === PaymentApi.CREDIT_CARD_TYPE.AMEX
    ? CVC_IMG.AMEX
    : CVC_IMG.DEFAULT;
}

export const CREDIT_CARD_VALID_LENGTH = {
  AMEX: [14, 15],
  DINERS: [14],
  JCB: [16],
  LASER: [16, 17, 18, 19],
  VISA: [13, 16, 19],
  MASTERCARD: [16, 19],
  MAESTRO: [12, 13, 14, 15, 16, 17, 18, 19],
  DISCOVER: [16]
};

export const CARDS: CreditCardDescription[] = [
  {
    name: PaymentApi.CREDIT_CARD_TYPE.AMEX,
    icon: CREDIT_CARD_IMG.AMEX,
    pattern: /^3[47]/,
    valid_length: CREDIT_CARD_VALID_LENGTH.AMEX
  },
  /*{
        name: PaymentApi.CREDIT_CARD_TYPE.AMEX,
        icon: CREDIT_CARD_IMG.AMEX,
        pattern: /^3[47]\d{13}/, // Omnipay
        valid_length: [15]
    },*/
  {
    name: PaymentApi.CREDIT_CARD_TYPE.DINERS,
    icon: CREDIT_CARD_IMG.DINERS,
    pattern: /^30[0-5]/, // Carte Blanche
    valid_length: CREDIT_CARD_VALID_LENGTH.DINERS
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.DINERS,
    icon: CREDIT_CARD_IMG.DINERS,
    pattern: /^36/, // International
    valid_length: CREDIT_CARD_VALID_LENGTH.DINERS
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.DINERS,
    icon: CREDIT_CARD_IMG.DINERS,
    pattern: /^3(0[0-5]|[68]\d)\d{11}/, // Omnipay (Carte Blanche and International)
    valid_length: CREDIT_CARD_VALID_LENGTH.DINERS
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.JCB,
    icon: CREDIT_CARD_IMG.JCB,
    pattern: /^35(2[89]|[3-8]\d)/,
    valid_length: CREDIT_CARD_VALID_LENGTH.JCB
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.JCB,
    icon: CREDIT_CARD_IMG.JCB,
    pattern: /^35(28|29|[3-8]\d)\d{12}/, // Omnipay
    valid_length: CREDIT_CARD_VALID_LENGTH.JCB
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.JCB,
    icon: CREDIT_CARD_IMG.JCB,
    pattern: /^(3\d{4}|2131|1800)\d{11}/, // Custom
    valid_length: CREDIT_CARD_VALID_LENGTH.JCB
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.LASER,
    pattern: /^(6304|670[69]|6771)/,
    valid_length: CREDIT_CARD_VALID_LENGTH.LASER
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.LASER,
    pattern: /^(6304|6706|6709|6771(?!89))\d{8}(\d{4}|\d{6,7})?/, // Omnipay
    valid_length: CREDIT_CARD_VALID_LENGTH.LASER
  },
  {
    // name: 'visa_electron',
    name: PaymentApi.CREDIT_CARD_TYPE.VISA,
    icon: CREDIT_CARD_IMG.VISA,
    pattern: /^(4026|417500|4508|4844|491(3|7))/,
    valid_length: CREDIT_CARD_VALID_LENGTH.VISA
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.VISA,
    icon: CREDIT_CARD_IMG.VISA,
    pattern: /^4/,
    valid_length: CREDIT_CARD_VALID_LENGTH.VISA
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.VISA,
    icon: CREDIT_CARD_IMG.VISA,
    pattern: /^4\d{12}(\d{3})?/, // Omnipay
    valid_length: CREDIT_CARD_VALID_LENGTH.VISA
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.MASTERCARD,
    icon: CREDIT_CARD_IMG.MASTERCARD,
    pattern: /^5[1-5]/,
    valid_length: CREDIT_CARD_VALID_LENGTH.MASTERCARD
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.MASTERCARD,
    icon: CREDIT_CARD_IMG.MASTERCARD,
    pattern: /^(5[1-5]\d{4}|677189)\d{10}/, // Omnipay
    valid_length: CREDIT_CARD_VALID_LENGTH.MASTERCARD
  },
  /*{
        name: PaymentApi.CREDIT_CARD_TYPE.MASTERCARD,
        icon: CREDIT_CARD_IMG.MASTERCARD,
        pattern: /^5[1-5]\d{14}/, // Custom
        valid_length: CREDIT_CARD_VALID_LENGTH.MASTERCARD
    },*/
  {
    name: PaymentApi.CREDIT_CARD_TYPE.MAESTRO,
    // 5000 00-5099 99, 5600 00-6999 99
    pattern: /^(5018|5020|5038|5893|6304|6759|676[1-3])/,
    valid_length: CREDIT_CARD_VALID_LENGTH.MAESTRO
  },
  /*{
        name: PaymentApi.CREDIT_CARD_TYPE.MAESTRO,
        pattern: /^(5[06-8]|6\d)\d{10,17}/, // Omnipay (overlaps with discover; example: 6011000000000012)
        valid_length: CREDIT_CARD_VALID_LENGTH.MAESTRO
    },*/
  {
    name: PaymentApi.CREDIT_CARD_TYPE.MAESTRO,
    // pattern: /^(?:5[06-8]\d\d|6304|6390|67\d\d)\d{8,15}/, // Custom
    pattern: /^(?:5[06-8]\d\d|6304|6390|676[1-3])\d{8,15}/, // Custom (Edit)
    valid_length: CREDIT_CARD_VALID_LENGTH.MAESTRO
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.DISCOVER,
    icon: CREDIT_CARD_IMG.DISCOVER,
    pattern:
      /^(6011|622(12[6-9]|1[3-9]\d|[2-8]\d{2}|9[0-1]\d|92[0-5]|64[4-9])|65)/,
    valid_length: CREDIT_CARD_VALID_LENGTH.DISCOVER
  },
  {
    name: PaymentApi.CREDIT_CARD_TYPE.DISCOVER,
    icon: CREDIT_CARD_IMG.DISCOVER,
    pattern: /^(6011|65\d{2}|64[4-9]\d)\d{12}|(62\d{14})/, // Omnipay
    // pattern: /^(6011|65\d{2}|64[4-9]\d)\d{12}|(622\d{13})/, // Omnipay (Edit)
    valid_length: CREDIT_CARD_VALID_LENGTH.DISCOVER
  }
];

/**
 * Copied over from Luminous-Core
 *
 **/

/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable max-len */
/* eslint-disable no-shadow, @typescript-eslint/no-shadow */

export function validateCreditCard(
  input: string
): CreditCardValidationResult | null {
  if (input) {
    const customIndexOf: (searchElement: any, fromIndex?: number) => number =
      function (item: any[]) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) {
            return i;
          }
        }
        return -1;
      };

    var __indexOf = ([] as any[]).indexOf || customIndexOf;

    var card: CreditCardDescription,
      card_type: CreditCardDescription | null,
      get_card_type: (number: string) => CreditCardDescription | null,
      is_valid_length: (
        number: string,
        card_type: CreditCardDescription
      ) => boolean,
      validate: (input: string) => CreditCardValidationResult,
      validate_number: (number: string) => CreditCardValidationResult,
      _i: number,
      _len: number,
      _ref: CreditCardDescription[];

    var options: Record<string, any> = {};

    if (typeof options.accept === 'undefined') {
      options.accept = (function () {
        var _i: number, _len: number, _results: string[];
        _results = [];
        for (_i = 0, _len = CARDS.length; _i < _len; _i++) {
          card = CARDS[_i];
          _results.push(card.name);
        }
        return _results;
      })();
    }

    _ref = options.accept;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      card_type = _ref[_i];
      if (
        __indexOf.call(
          (function () {
            var _j, _len1, _results;
            _results = [];
            for (_j = 0, _len1 = CARDS.length; _j < _len1; _j++) {
              card = CARDS[_j];
              _results.push(card.name);
            }
            return _results;
          })(),
          card_type
        ) < 0
      ) {
        throw new Error(
          "Credit card type '" + card_type + "' is not supported"
        );
      }
    }

    get_card_type = function (number: string) {
      var _j: number, _len1: number, _ref1: CreditCardDescription[];
      _ref1 = (function () {
        var _k: number,
          _len1: number,
          _ref1: string,
          _results: CreditCardDescription[];
        _results = [];
        for (_k = 0, _len1 = CARDS.length; _k < _len1; _k++) {
          card = CARDS[_k];
          if (
            ((_ref1 = card.name), __indexOf.call(options.accept, _ref1) >= 0)
          ) {
            _results.push(card);
          }
        }
        return _results;
      })();
      for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
        card_type = _ref1[_j];
        if (number.match(card_type.pattern)) {
          return card_type;
        }
      }
      return null;
    };

    is_valid_length = function (
      number: string,
      card_type: CreditCardDescription
    ) {
      var _ref1: number;
      return (
        (_ref1 = number.length),
        __indexOf.call(card_type.valid_length, _ref1) >= 0
      );
    };

    validate_number = (function (_this) {
      return function (number: string) {
        var length_valid: boolean, luhn_valid: boolean;
        card_type = get_card_type(number);
        luhn_valid = false;
        length_valid = false;
        if (card_type !== null) {
          luhn_valid = isValidLuhn(number);
          length_valid = is_valid_length(number, card_type);
        }
        return {
          card: card_type,
          valid: luhn_valid && length_valid,
          luhnValid: luhn_valid,
          lengthValid: length_valid
        };
      };
    })(this);

    validate = (function (_this) {
      return function () {
        return validate_number(normalize(input));
      };
    })(this);

    return validate(input);
  } else {
    return null;
  }
}

function isValidLuhn(luhn: string): boolean {
  let digit: number;
  let n: number;
  let sum = 0;
  let _j: number;
  let _len1: number;
  let _ref1: string[];

  _ref1 = luhn.split('').reverse();

  for (n = _j = 0, _len1 = _ref1.length; _j < _len1; n = ++_j) {
    digit = _ref1[n] as unknown as number;
    digit = +digit;

    if (n % 2) {
      digit *= 2;

      if (digit < 10) {
        sum += digit;
      } else {
        sum += digit - 9;
      }
    } else {
      sum += digit;
    }
  }

  return sum % 10 === 0;
}

function normalize(number: string) {
  return number.replace(/[ -]/g, '');
}
