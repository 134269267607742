import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationsApiService, responseData } from '@element451-libs/api451';
import { cachedIn } from '@element451-libs/common451';
import {
  expectAll,
  mapToPayload,
  waitFor
} from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { NotificationsOverlayService } from '../../components';
import { DashboardRoutingService } from '../dashboard-routing/dashboard-routing.service';
import { Forms } from '../forms';
import { removeSnapAppSuffix } from '../snap-app';
import * as fromSnapApp from '../snap-app/snap-app.actions';
import { SNAP_APP_ACTIONS } from '../snap-app/snap-app.actions';
import { UserApplications } from '../user-applications/user-applications.service';
import { UserData } from '../user-data';
import * as fromSteps from './steps.actions';
import { STEPS_ACTIONS } from './steps.actions';
import { normalizeStep } from './steps.helpers';
import { Steps } from './steps.service';

@Injectable()
export class StepsReadEffects {
  constructor(
    private actions$: Actions<
      fromSteps.StepsAction | fromSnapApp.SnapAppAction
    >,
    private steps: Steps,
    private applicationsApi: ApplicationsApiService,
    private userApplications: UserApplications,
    private userData: UserData,
    private dashboardRouting: DashboardRoutingService
  ) {}

  goToStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(STEPS_ACTIONS.GO_TO_STEP),
        tap(action => {
          this.dashboardRouting.goToStep(action.payload.stepId);
        })
      ),
    { dispatch: false }
  );

  goToInfoRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(STEPS_ACTIONS.GO_TO_INFO_REQUEST),
        tap(action => {
          this.dashboardRouting.goToRecommendations();
        })
      ),
    { dispatch: false }
  );

  stepOpened$ = createEffect(() =>
    this.actions$.pipe(
      ofType(STEPS_ACTIONS.STEP_OPENED),
      map(action => new fromSteps.LoadStepRequestAction(action.payload.step))
    )
  );

  loadStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(STEPS_ACTIONS.LOAD_STEP_REQUEST),
      cachedIn(this.steps.entities$),
      mapToPayload,
      waitFor(this.userData.fieldSlugNameMappings$),
      withLatestFrom(this.userApplications.activeRegistrationId$),
      expectAll,
      mergeMap(([id, regId]) =>
        this.applicationsApi.getStep(regId, id).pipe(
          responseData,
          withLatestFrom(
            this.userApplications.activeRegistrationId$,
            this.userData.fieldNameSlugMappings$
          ),
          map(([step, registrationId, mappings]) => ({
            raw: step,
            normalized: normalizeStep(step, registrationId),
            mappings
          })),
          map(payload => new fromSteps.LoadStepSuccessAction(payload)),
          catchError(err => of(new fromSteps.LoadStepFailAction(err)))
        )
      )
    )
  );
}

@Injectable()
export class StepsWriteEffects {
  constructor(
    private actions$: Actions<
      fromSteps.StepsAction | fromSnapApp.SnapAppAction
    >,
    private applicationsApi: ApplicationsApiService,
    private userApplications: UserApplications,
    private forms: Forms,
    private notifications: NotificationsOverlayService
  ) {}

  submitSectionForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(STEPS_ACTIONS.SUBMIT_SECTION_FORM_REQUEST),
      withLatestFrom(
        this.userApplications.selectedApplicationGuid$,
        this.userApplications.activeRegistrationId$
      ),
      concatMap(([request, appGuid, regId]) =>
        this.applicationsApi
          .submitForm(
            appGuid,
            removeSnapAppSuffix(request.payload.formGuid),
            regId,
            request.payload.data
          )
          .pipe(
            responseData,
            withLatestFrom(this.forms.entities$),
            map(([response, forms]) => {
              const fields = forms[request.payload.formGuid].fields;
              return { response, fields };
            }),
            map(data => {
              const successAction =
                new fromSteps.SubmitSectionFormSuccessAction({
                  ...data,
                  updatedFields: request.payload.data.fields
                });
              return successAction.setTransaction(request.meta.optimistic.id);
            }),
            catchError(err => {
              let errorAction = new fromSteps.SubmitSectionFormFailAction(err);
              errorAction = errorAction.setTransaction(
                request.meta.optimistic.id
              );
              return of(errorAction);
            })
          )
      )
    )
  );

  saveRepeaterItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(STEPS_ACTIONS.SAVE_REPEATER_ITEM_REQUEST),
      withLatestFrom(
        this.userApplications.selectedApplication$,
        this.userApplications.activeRegistrationId$
      ),
      concatMap(([{ payload, metadata }, app, regId]) =>
        this.applicationsApi
          .submitForm(
            app.guid,
            removeSnapAppSuffix(payload.formGuid),
            regId,
            payload.data
          )
          .pipe(
            responseData,
            withLatestFrom(this.forms.entities$),
            map(([response, forms]) => {
              const fields = forms[payload.formGuid].fields;
              const weight = payload.data.fields[0]['weight'];
              return { response, fields, weight };
            }),
            map(
              data =>
                new fromSteps.SaveRepeaterItemSuccessAction(data, metadata)
            ),
            catchError(err =>
              of(new fromSteps.SaveRepeaterItemFailAction(err, metadata))
            )
          )
      )
    )
  );

  removeFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(STEPS_ACTIONS.REMOVE_FILE_REQUEST),
      mapToPayload,
      withLatestFrom(
        this.userApplications.selectedApplicationGuid$,
        this.forms.entities$,
        this.userApplications.activeRegistrationId$
      ),
      map(([removeFilePayload, appGuid, formEntities, registrationId]) => {
        const formGuid = removeSnapAppSuffix(removeFilePayload.formGuid);
        const fileGuid = removeFilePayload.fileGuid;
        const fieldName = removeFilePayload.fieldName;
        const fields = formEntities[formGuid].fields;
        return {
          formGuid,
          appGuid,
          registrationId,
          fileGuid,
          fieldName,
          fields
        };
      }),
      concatMap(
        ({ appGuid, formGuid, fileGuid, fieldName, fields, registrationId }) =>
          this.applicationsApi
            .removeFile(appGuid, formGuid, fileGuid, registrationId)
            .pipe(
              responseData,
              map(
                data =>
                  new fromSteps.RemoveFileSuccessAction({
                    ...data,
                    fields,
                    fileGuid,
                    fieldName
                  })
              ),
              catchError(err => of(new fromSteps.RemoveFileFailAction(err)))
            )
      )
    )
  );

  deleteRepeaterItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(STEPS_ACTIONS.DELETE_REPEATER_ITEM_REQUEST),
      mapToPayload,
      concatMap(item => {
        const { applicationGuid, registrationId, slug, weight } = item;
        const formGuid = removeSnapAppSuffix(item.formGuid);
        return this.applicationsApi
          .deleteRepeaterItem(
            applicationGuid,
            registrationId,
            formGuid,
            slug,
            weight
          )
          .pipe(
            responseData,
            map(data => ({ ...data, weight, slug })),
            map(data => new fromSteps.DeleteRepeaterItemSuccessAction(data)),
            catchError(err =>
              of(new fromSteps.DeleteRepeaterItemFailAction(err))
            )
          );
      })
    )
  );

  submitFormError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          STEPS_ACTIONS.SUBMIT_SECTION_FORM_FAIL,
          STEPS_ACTIONS.REMOVE_FILE_FAIL,
          STEPS_ACTIONS.DELETE_REPEATER_ITEM_FAIL,
          STEPS_ACTIONS.SAVE_REPEATER_ITEM_FAIL
        ),
        mapToPayload,
        tap(response => {
          const errorMessage = extractErrorMessage(response);

          this.notifications.open({
            message: errorMessage,
            type: 'error'
          });
        })
      ),
    { dispatch: false }
  );
}

function extractErrorMessage(response: HttpErrorResponse) {
  // Check if there are validation errors in the `error.error.data`
  if (response?.error?.data?.data) {
    const validationErrors = response.error.data.data;

    // Collect all validation error messages from the nested structure
    const messages = Object.values(validationErrors).flat().join(', ');

    return messages;
  }

  // Fall back to `userMessage` if no validation errors are found
  return response?.error?.userMessage || 'An unknown error occurred';
}

@Injectable()
export class SnapAppStepEffects {
  constructor(
    private actions$: Actions<fromSnapApp.SnapAppAction>,
    private userApplications: UserApplications,
    private userData: UserData
  ) {}

  loadSnapApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SNAP_APP_ACTIONS.LOAD_SNAP_APP_SUCCESS),
      mapToPayload,
      withLatestFrom(
        this.userApplications.activeRegistrationId$,
        this.userData.fieldSlugNameMappings$
      ),
      map(([snapApp, registrationId, mappings]) => {
        const snapAppSteps = snapApp.sections.map(sectionStep => ({
          raw: sectionStep,
          normalized: normalizeStep(sectionStep, registrationId, {
            isSnapApp: true
          })
        }));
        return new fromSteps.LoadSnapAppStepAction({
          snapAppSteps,
          mappings
        });
      })
    )
  );
}
