import { NgModule } from '@angular/core';
import { BytesToSizePipeModule } from '@element451-libs/utils451/files';
import { Nl2brModule } from '@element451-libs/utils451/nl2br';
import { SanitizationModule } from '@element451-libs/utils451/sanitization';
import { Utils451UserModule } from '@element451-libs/utils451/user';
import { CapitalizePipe } from './capitalize.pipe';
import { ChipColorPipe } from './chip-color.pipe';
import { CondenseNumberPipe } from './condense-numbers.pipe';
import { ConversationDeliveryMethodIconPipe } from './conversation-delivery-method-icon.pipe';
import { CountSmsCharsFromHtmlPipe } from './count-sms-chars-from-html.pipe';
import {
  BillingStateCodeToNamePipe,
  CountryCodeToNamePipe
} from './countries.pipe';
import { CssImageWrapPipe } from './css-image-wrap.pipe';
import {
  CalendarDatePipe,
  DateChronologicalPipe,
  DatePipe,
  DateUTCPipe,
  DateUnixPipe,
  FromDateToNowAccessibilityPipe,
  FromDateToNowPipe,
  FromDateToNowShortPipe,
  LegacyDatePipe
} from './date.pipe';
import { DecisionColorPipe } from './decision-color.pipe';
import { ElsePipe } from './else.pipe';
import { ElmEntitiesPipe } from './entities.pipe';
import { ElmEntityPipe } from './entity.pipe';
import { ObjectPropertyPipe } from './object-property.pipe';
import { PagesThubmnailPipe } from './pages-thumbnail.pipe';
import { PlaceholderPipe } from './placeholder.pipe';
import { RoundPipe } from './round.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { StripHtmlPipe } from './strip-html.pipe';

const MODULES = [
  SanitizationModule,
  BytesToSizePipeModule,
  Nl2brModule,
  Utils451UserModule
];

const PIPES = [
  CapitalizePipe,
  ChipColorPipe,
  ConversationDeliveryMethodIconPipe,
  CountSmsCharsFromHtmlPipe,
  CssImageWrapPipe,
  DatePipe,
  DateUTCPipe,
  DateUnixPipe,
  DateChronologicalPipe,
  ElsePipe,
  ElmEntitiesPipe,
  ElmEntityPipe,
  FromDateToNowPipe,
  FromDateToNowShortPipe,
  CalendarDatePipe,
  LegacyDatePipe,
  ObjectPropertyPipe,
  PlaceholderPipe,
  RoundPipe,
  SlugifyPipe,
  StripHtmlPipe,
  CondenseNumberPipe,
  DecisionColorPipe,
  CountryCodeToNamePipe,
  BillingStateCodeToNamePipe,
  PagesThubmnailPipe,
  FromDateToNowAccessibilityPipe
];

@NgModule({
  imports: [MODULES],
  declarations: [PIPES],
  exports: [PIPES, MODULES]
})
export class PipesModule {}
