<div [formGroup]="group">
  <mat-form-field class="lum-mat-component" [color]="fieldControl?.errors && fieldControl?.dirty ? 'warn' : ''">
    <mat-label>
      <span [innerHTML]="model | lumDfRequiredAsterisk: fieldControl?.errors"></span>
    </mat-label>

    <input
      *ngIf="mask"
      matInput
      #inputControl
      type="text"
      [patterns]="patterns"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [mask]="mask"
      (blur)="onBlur()"
      [formControlName]="model.key"
    />

    <input *ngIf="!mask" matInput #inputControl type="text" (blur)="onBlur()" [formControlName]="model.key" />

    <mat-hint class="lum-df-hint-wrapper">
      <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"></span>
    </mat-hint>
  </mat-form-field>
</div>
