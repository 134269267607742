import {
  doesUserHaveValidPhoneQuery,
  doesUserHaveValidPhoneQueryVariables,
  User
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { User };

export const userValidPhoneQuery = gql<
  doesUserHaveValidPhoneQuery,
  doesUserHaveValidPhoneQueryVariables
>`
  query doesUserHaveValidPhone($userId: String!) {
    doesUserHaveValidPhone(input: { userId: $userId })
  }
`;
