import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ELEMENT451_ADMINISTRATOR_GROUP_ID } from '@element451-libs/config451';
import { AuthApi, TeamsApi } from '@element451-libs/models451';
import { reduce, some } from 'lodash';
import { Observable, Operator } from 'rxjs';
import { map } from 'rxjs/operators';

export const CURRENT_USER$ = new InjectionToken<Observable<AuthApi.Account>>(
  'CURRENT USER STREAM TOKEN!'
);

@Injectable()
export class CurrentUser<
  V extends AuthApi.Account = AuthApi.Account
> extends Observable<V> {
  constructor(@Inject(CURRENT_USER$) source?: Observable<V>) {
    super();

    if (source) {
      this.source = source;
    }
  }

  lift<R>(operator: Operator<V, R>): Observable<R> {
    const observable = new CurrentUser<any>();
    observable.source = this;
    observable.operator = operator;
    return observable;
  }

  getFirstName$() {
    return this.pipe(map(({ properties }) => properties?.first_name || ''));
  }

  getUserPermissions$() {
    return this.pipe(
      map((user: AuthApi.Account) => user.permissions),
      map(permissions =>
        reduce(
          permissions,
          (table, permission) => ({
            ...table,
            [permission.slug]: permission
          }),
          {}
        )
      )
    );
  }

  getUserTeams$() {
    return this.pipe(
      map((user: AuthApi.Account) => user.properties?.user_relationships || []),
      map(relationships =>
        relationships
          .filter(
            r =>
              r.relation === TeamsApi.TeamMemberType.PrimaryTeamMember ||
              r.relation === TeamsApi.TeamMemberType.TeamMember
          )
          .map(r => r.target)
      )
    );
  }

  getUserPrimaryTeam$() {
    return this.pipe(
      map((user: AuthApi.Account) => user.properties?.user_relationships || []),
      map(relationships =>
        relationships.find(
          r => r.relation === TeamsApi.TeamMemberType.PrimaryTeamMember
        )
      ),
      map(team => team?.target)
    );
  }

  getSentryContext$() {
    return this.pipe(
      map((user: AuthApi.Account) => ({
        user: { id: user?.id, email: user?.properties?.email },
        permissions: user?.permissions?.reduce(
          (acc, { name, slug }) => ({ ...acc, [slug]: name }),
          {}
        )
      }))
    );
  }

  isSuperAdmin$() {
    return this.pipe(
      map((user: AuthApi.Account) =>
        some(
          user?.groups,
          group => group._id === ELEMENT451_ADMINISTRATOR_GROUP_ID
        )
      )
    );
  }

  isElementUser$() {
    return this.pipe(map(({ properties }) => isElementUser(properties.email)));
  }
}

export const isElementUser = (email: string) => {
  if (!email) {
    return false;
  }
  return email.endsWith('@element451.com');
};
